const serverIP = "https://echurchapi.mycop.church";
// const serverIP = "https://trainingapi.mycop.church/";
// const serverIP = "http://127.0.0.1:8000";

module.exports = {
  BASE_URL: serverIP+ "/",
  API_URL: serverIP+ "/api",
  MEDIA_URL: serverIP,
  copyright: `© ${new Date().getFullYear()} The Church of Pentecost – eChurch Global. All Rights Reserved`,
  version: "1.0.0",
  DATE_FORMAT: 'YYYY-MM-DD',
};

