import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}changelayout`}
        component={asyncComponent(() => import("../containers/Customizer"))}
      />
      <Route
        path={`${match.url}dashboard`}
        component={asyncComponent(() => import("../containers/Dashboard"))}
      />
      <Route
        path={`${match.url}user`}
        component={asyncComponent(() => import("./user"))}
      />
      <Route
        path={`${match.url}company`}
        component={asyncComponent(() => import("./company"))}
      />
      <Route
        path={`${match.url}member`}
        component={asyncComponent(() => import("./member"))}
      />
      <Route
        path={`${match.url}activity`}
        component={asyncComponent(() => import("./activity"))}
      />
      <Route
        path={`${match.url}settings`}
        component={asyncComponent(() => import("./settings"))}
      />
      <Route
        path={`${match.url}reports`}
        component={asyncComponent(() => import("./reports"))}
      />
         <Route
        path={`${match.url}services`}
        component={asyncComponent(() => import("./services"))}
      />
      <Route
          path={`${match.url}video`}
          component={asyncComponent(() => import("./video"))}
      />
      {/* Not Found Page */}
      <Route
        path={`*`}
        component={asyncComponent(() =>
          import("../components/NotFound/NotFound")
        )}
      />
    </Switch>
  </div>
);

export default App;
